<template>
  <b-modal
    id="add-new-chat-room"
    ref="add-chat-room"
    title="Add new number"
    size="md"
    hide-footer
    @hidden="resetModal"
  >
    <slot name="body">
      <b-form-group>
        <b-row>
          <b-col cols="10">
            <vue-phone-number-input
              v-model="phoneNumber"
              class="form-control"
              :only-countries="allowedCountries"
              default-country-code="US"
              :required="true"
              :clearable="true"
              @keyup.native.enter="addNumber"
              @update="validateNumberOnEvent"
            />
          </b-col>
          <b-col class="plus-col">
            <fa-icon size="lg" icon="plus-square" class="plus" @click="addNumber" />
          </b-col>
        </b-row>
      </b-form-group>
      <b-card v-for="number in numberList" :key="number" no-body align-v="center" class="mb-1">
        <b-row class="mb-2 mt-2 ml-1">
          <b-col cols="10">
            <b-card-text>{{ number }}</b-card-text>
          </b-col>
          <b-col cols="2">
            <fa-icon size="lg" icon="ban" class="remove" @click="removeNumber(number)" />
          </b-col>
        </b-row>
      </b-card>
      <hr class="pt-1 pb-1">
      <b-button type="submit" variant="primary" :disabled="numberList.length <= 0" @click="onSubmit">
        Start Conversation
      </b-button>
    </slot>
  </b-modal>
</template>

<script>
  import store from "@/store"
  import VuePhoneNumberInput from 'vue-phone-number-input'
  import 'vue-phone-number-input/dist/vue-phone-number-input.css'
  import { formatPhoneToCountryCodeStyle, formatPhoneToCountryCodeStyleWithout1 } from '@/common/modules/formatters'

  export default {
    name: 'AddNewChatRoom',
    components: { VuePhoneNumberInput },
    props: {
      virtualPhoneId: {
        type: String,
        required: true,
      },
      virtualPhoneNumber: {
        type: String,
      },
      invitedNumber: {
        type: String,
      },
      resetRooms: {
        type: Function,
      },
    },
    data() {
      return {
        phoneNumber: null,
        validNumber: false,
        allowedCountries: ['US'],
        numberList: [],
      }
    },
    mounted() {
      this.$refs['add-chat-room'].show()
    },
    methods: {
      addNumber() {
        if(!this.validNumber) {
          this.errorToast("Please enter a valid number.")
        } else if(this.virtualPhoneNumber.includes(this.phoneNumber.replace(/\D/g,''))) {
          this.errorToast("Can't add your own number to conversation.")
        } else if(this.numberList.includes(this.phoneNumber)) {
          this.errorToast("This number has already been added.")
        } else if (this.numberList.length + 1 > 10) {
          this.errorToast("Chat room cannot contain more than 10 participants.")
        } else {
          this.numberList.push(this.phoneNumber)
          this.phoneNumber = null
          this.validNumber = false
        }
      },
      removeNumber(number) {
        this.numberList = this.numberList.filter(num => num !== number)
      },
      async onSubmit() {
        const formattedNumbers = []
        this.numberList.forEach(number => {
          const shortCode = number.length < 14
          const formattedNumber = shortCode ? formatPhoneToCountryCodeStyleWithout1(number) : formatPhoneToCountryCodeStyle(number)
          formattedNumbers.push(formattedNumber)
        })

        let newRoom = {}

        // Group Message
        if (this.numberList.length > 1) {
          formattedNumbers.push(this.virtualPhoneNumber)

          newRoom = {
            group_participant_list: formattedNumbers,
            virtualPhoneId: this.virtualPhoneId,
          }
        // Regular Message
        } else {
          newRoom = {
            number: formattedNumbers[0],
            virtualPhoneId: this.virtualPhoneId,
          }
        }

        await store.dispatch('sms/createRoom', newRoom)
        this.$nextTick(() => {
          this.$emit('hideNewRoom')
          this.$emit('resetRooms')
        })
      },
      resetModal(){
        this.$refs['add-chat-room'].hide()
        this.$emit('hideNewRoom')
      },
      validateNumberOnEvent(event){
        this.validNumber = event.nationalNumber?.length >= 4
      },
      errorToast(message) {
        this.$bvToast.toast(message, {
          variant: 'danger',
          title: 'Error',
          autoHideDelay: 5000,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.plus-col {
  display: flex;
  align-items: center;
}
.plus {
  display: block;
  color: #1976d2;
}
.plus:hover {
  transform: scale(1.1);
  opacity: .7;
}
.remove {
  color: $red;
}
.remove:hover {
  transform: scale(1.1);
  opacity: .8;
}
</style>

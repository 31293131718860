<template>
  <b-modal
    id="name-chat-room"
    ref="name-chat-room"
    title="Change name"
    size="md"
    hide-footer
    @hidden="resetModal"
  >
    <b-row>
      <b-col cols="10">
        <span>{{ currentRoomName }}</span>
      </b-col>
      <b-col v-if="roomNameOverride" cols="2">
        <fa-icon size="lg" icon="ban" class="remove" @click="onClear" />
      </b-col>
    </b-row>

    <b-row class="pt-2 h-50">
      <b-col cols="7">
        <b-input v-model="chatRoomName" type="text" />
      </b-col>
      <b-col cols="5">
        <b-button v-if="!loading" type="submit" variant="primary" class="mt-2" :disabled="chatRoomName?.length <= 0" @click="onSubmit">
          Update
        </b-button>
        <ct-centered-spinner v-else />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: 'NameChatRoomModal',
  components: { CtCenteredSpinner },
  props: {
    phoneId: String,
    roomId: String,
  },
  data() {
    return {
      chatRoomName: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('sms', ['rooms']),
    currentRoomName() {
      return this.rooms.find(r => r.roomId === this.roomId).roomName
    },
    roomNameOverride() {
      return this.rooms.find(r => r.roomId === this.roomId).room_name_override
    },
  },
  mounted() {
    this.$refs['name-chat-room'].show()
  },
  methods: {
    ...mapActions('sms', ['nameRoom', 'getRooms']),
    async onSubmit() {
      await this.nameRoom({ roomId: this.roomId, name: this.chatRoomName })
      this.$refs['name-chat-room'].hide()
    },
    // Submitting with a null chatRoomName will clear the existing name if one is set.
    async onClear() {
      this.loading = true
      await this.nameRoom({ roomId: this.roomId, name: null })
      await this.getRooms({ id: this.phoneId })
      this.loading = false
    },
    resetModal() {
      this.$refs['name-chat-room'].hide()
      this.$emit('hideNameRoom')
    },
  },
}
</script>

<style lang="scss" scoped>
.remove {
  color: $red;
}
.remove:hover {
  transform: scale(1.1);
  opacity: .8;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "name-chat-room",
      attrs: {
        id: "name-chat-room",
        title: "Change name",
        size: "md",
        "hide-footer": "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("span", [_vm._v(_vm._s(_vm.currentRoomName))]),
          ]),
          _vm.roomNameOverride
            ? _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("fa-icon", {
                    staticClass: "remove",
                    attrs: { size: "lg", icon: "ban" },
                    on: { click: _vm.onClear },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-2 h-50" },
        [
          _c(
            "b-col",
            { attrs: { cols: "7" } },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.chatRoomName,
                  callback: function ($$v) {
                    _vm.chatRoomName = $$v
                  },
                  expression: "chatRoomName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              !_vm.loading
                ? _c(
                    "b-button",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        type: "submit",
                        variant: "primary",
                        disabled: _vm.chatRoomName?.length <= 0,
                      },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("\n        Update\n      ")]
                  )
                : _c("ct-centered-spinner"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <b-container class="sms-chat-page">
      <virtual-phone-masthead
        title="Messaging as"
        @changed="resetRooms"
        @loaded="loadedPhoneMasthead"
      />

      <b-container v-if="loaded">
        <div v-if="arePhonesActive">
          <chat-window
            ref="myChat"
            height="calc(100vh - 210px)"
            :accepted-files="acceptedFiles"
            :current-user-id="currentUserId"
            :rooms="rooms"
            :room-id="currentRoomId"
            :messages="messages"
            :show-audio="false"
            :show-reaction-emojis="false"
            :loading-rooms="loadingRooms"
            :rooms-loaded="roomsLoaded"
            :messages-loaded="messagesLoaded"
            :message-actions="messageActions"
            :menu-actions="menuActions"
            :room-actions="roomActions"
            :styles="chatStyle"
            @send-message="sendMessage"
            @delete-message="deleteMessage"
            @fetch-messages="fetchMessages"
            @add-room="addRoom"
            @open-file="downloadFile"
            @menu-action-handler="menuActionHandler($event)"
            @room-action-handler="menuActionHandler($event)"
          />
          <add-new-chat-room
            v-if="showAddRoom"
            ref="add-chat-room"
            :virtual-phone-id="selectedPhone.id"
            :virtual-phone-number="selectedPhone.number"
            :invited-number="invitedNumber"
            @resetRooms="resetRooms"
            @showAddRoom="showAddRoom"
            @hideNewRoom="hideNewRoom"
          />
          <name-chat-room-modal
            v-if="showNameRoom"
            ref="name-chat-room"
            :phone-id="selectedPhone.id"
            :room-id="nameRoomId"
            @showNameRoom="showNameRoom"
            @hideNameRoom="hideNameRoom"
          />
        </div>
        <div v-else>
          <p>There are currently no active phones.</p>
        </div>
      </b-container>
      <ct-centered-spinner v-else height="260px" />
    </b-container>
    <contact-modal ref="contact-modal" :title="'Add a contact'" :type="'add'" :is-phone-contact="true" @contact-modal-close="contactModalClosed" />
  </div>
</template>

<script>

import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import AddNewChatRoom from '@/components/SMSChat/AddNewChatRoom'
import NameChatRoomModal from '@/components/SMSChat/NameChatRoomModal'
import VirtualPhoneMasthead from '@/components/VirtualPhones/VirtualPhoneMasthead'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { connectWithAccountId } from '@/common/modules/actionCable'
import ContactModal from '@/components/ContactModal'

export default {
  name: 'SMSChat',
  components: { ChatWindow, AddNewChatRoom, NameChatRoomModal, VirtualPhoneMasthead, CtCenteredSpinner, ContactModal },
  data() {
    return {
      acceptedFiles: "application/json, application/zip, audio/mp4, audio/mpeg, audio/wav, audio/webm," +
        " image/gif, image/png, image/jpeg, text/csv, text/plain, video/mp4,video/quicktime, video/webm, video/3gpp",
      bus: new Vue(),
      dateFormat: { day: 'numeric', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' },
      loaded: false,
      loadingRooms: true,
      roomsLoaded: true,
      currentUserId: '',
      currentRoomId: null,
      nameRoomId: null,
      invitedNumber: '',
      showAddRoom: false,
      showNameRoom: false,
      messageActions: [
        {
          name: 'deleteMessage',
          title: 'Delete Message',
        },
      ],
      menuActions: [],
      chatStyle: {
        message: {
          backgroundMe: '#424182',
          colorDate: '#505a62',
          color: '#818080',
        },
        room: {
          colorUsername: '#353535',
          backgroundCounterBadge: '#F96A2B',
        },
        icons: {
          send: '#01CBCF',
          emoji: '#01CBCF',
          paperclip: '#01CBCF',
        },
      },
    }
  },
  channels: {
    PhoneNotificationChannel: {
      async received(data) {
        if(data.message.event === 'incoming_sms') {
          if(data.message.textMessage.chat_room_id === this.currentRoomId) {
            await this.resetMessages()
          }
          await this.resetRooms()
        }
      },
    },
  },

  computed: {
    ...mapGetters('virtualPhones', ['selectedPhone']),
    ...mapGetters('sms', ['rooms', 'messages', 'messagesLoaded']),
    ...mapGetters('account', ['isNotAccountOwner', 'people']),
    ...mapGetters('chatRooms', ['currentChatRoom']),

    arePhonesActive() {
      return this.selectedPhone
    },

    roomActions() {
      return this.menuActions.filter(ma => ma.name !== 'unmuteRoom' && ma.name !== 'muteRoom' && ma.name !== 'createContact')
    },
  },

  watch: {
    currentChatRoom(value) {
      this.currentRoomId = value.id

      this.resetRooms()
    },
    currentRoomId(value) {
      if(value) {
        this.configureMenuActions(value)
      }
    },
  },

  async mounted() {
    this.configureMenuActions()
    if (this.people.length === 0) {
      await this.loadAccount()
    }

    if(!this.$cable._isReset) {
      await connectWithAccountId(this.$cable)
    }
    this.$cable.subscribe({
      channel: 'PhoneNotificationChannel',
    })
  },

  methods: {
    ...mapActions('account', ['loadAccount']),
    ...mapActions('virtualPhones', ['loadVirtualPhones']),
    ...mapActions('sms', ['deleteMessage', 'sendMessage', 'getMessages', 'getRooms', 'deleteRoom']),
    addRoom() {
      this.showAddRoom = true
    },
    configureMenuActions() {
      this.resetMenuActions()

      const currentRoom = this.rooms?.find(r => r.roomId === this.currentRoomId)

      if(currentRoom.notifications_muted) {
        this.menuActions.push({ name: 'unmuteRoom', title: 'Unmute Notifications' })
      } else {
        this.menuActions.push({ name: 'muteRoom', title: 'Mute Notifications' })
      }

      if(currentRoom.users.length <= 2) {
        this.menuActions.push({ name: 'createContact', title: 'Create Contact' })
      }
    },
    async menuActionHandler({ roomId, action }) {
      switch (action.name) {
        case 'deleteRoom':
          await this.deleteRoom(roomId)
          break
        case 'nameRoom':
          await this.nameRoom(roomId)
          break
        case 'muteRoom':
          await this.muteRoom(roomId)
          this.configureMenuActions()
          break
        case 'unmuteRoom':
          await this.unmuteRoom(roomId)
          this.configureMenuActions()
          break
        case 'createContact':
          await this.addContactFromRoom(roomId)
          break
      }
    },
    async deleteRoom(roomId) {
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this conversation history?', {
          title: 'Delete conversation history?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
      })

      if(res) {
        try {
          await this.$store.dispatch('sms/deleteRoom', roomId)
        } catch(error) {
          this.$bvToast.toast('A problem occurred deleting this conversation history.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }
      }
    },
    nameRoom(roomId) {
      this.nameRoomId = roomId
      this.showNameRoom = true
    },
    async muteRoom(roomId) {
      await this.$store.dispatch('sms/muteRoom', roomId)
    },
    async unmuteRoom(roomId) {
      await this.$store.dispatch('sms/unmuteRoom', roomId)
    },
    hideNewRoom() {
      this.invitedNumber = ''
      this.showAddRoom = false
    },
    hideNameRoom() {
      this.showNameRoom = false
    },
    async resetRooms() {
      if(this.selectedPhone) {
        await this.getRooms({
          id: this.selectedPhone.id,
        })

        await this.loadVirtualPhones()

        if(this.rooms.length > 0) {
          this.currentRoomId = this.rooms[0].roomId
        }

        this.currentUserId = this.selectedPhone.number
      }
      this.loadingRooms = false
      this.loaded = true
    },
    async resetMessages() {
      if(this.rooms.length > 0) {
        await this.getMessages({
          chat_room_id: this.currentRoomId,
        })
      }
    },
    resetMenuActions() {
      this.menuActions = [
        {
          name: 'deleteRoom',
          title: 'Delete Conversation',
        },
        {
          name: 'nameRoom',
          title: 'Name Conversation',
        },
      ]
    },
    downloadFile(message, file) {
      file = message.file.file
      const byteCharacters = atob(file.body)
      const byteNumbers = new Array(byteCharacters.length)
      for(let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: file.type })

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      const typeParts = file.type.split('/')
      downloadLink.download = file.s3_id + "." + typeParts[typeParts.length - 1]

      downloadLink.click()
    },
    async addContactFromRoom(roomId) {
      let phone_number = this.rooms.find(r => r.roomId === roomId).roomName.replace(/[()\s-+]/g, '')
      phone_number = phone_number.substring(1)
      let contact = {
        person_phones: [{ phone_number: phone_number }],
        person_addresses: [],
        person_emails: [],
      }
      await this.$refs['contact-modal'].show(contact)
    },
    async contactModalClosed() {
      await this.resetRooms()
    },
    // Triggered every time when we choose virtual phone or switch chat
    async fetchMessages({ room, options }) {
      if(room?.roomId) {
        if (options.reset) this.currentRoomId = room.roomId
        this.currentUserId = this.selectedPhone.number
        await this.resetMessages()
      }
    },
    async sendMessage({ content, files }) {
      let fileBlobs = []

      try {
        if(files?.length >= 1) {
          files.forEach(file => {
            if(file.size > 500000) {
              throw new Error('Attachment too large. Maximum file size for attachments is 500KB.')
            }

            fileBlobs.push({ blob: file.blob })
          })
        }

        let message = {
          content,
          roomId: this.currentRoomId,
        }
        await this.$store.dispatch('sms/sendMessage', { message: message, files: fileBlobs, vm: this })
      } catch(e) {
        this.$bvToast.toast(e.message, {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 4000,
        })
      }
    },
    async loadedPhoneMasthead() {
      await this.resetRooms()
      await this.resetMessages()
      this.loaded = true
    },
    async deleteMessage(messageData) {
      await this.$store.dispatch('sms/deleteMessage', messageData.message._id)
    },
  },
}
</script>

<style lang="scss">
.attachments-expired {
  color: #ff2e5f;
  font-size: 12px !important;
}
.vac-message-card {
  color: #606060 !important;
  font-size: 16px !important;

  &.vac-message-current {
    color: white !important;
    .vac-text-timestamp {
      color: #bcbcbc !important;
    }
  }
}
.vac-menu-item {
  color: #000000 !important;
}
.warning-btn {
  display: flex;
  justify-content: center;
}
.warning-text {
    color: red;
    margin: 16px 0;
    text-align: center;
}
</style>

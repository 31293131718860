var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "sms-chat-page" },
        [
          _c("virtual-phone-masthead", {
            attrs: { title: "Messaging as" },
            on: { changed: _vm.resetRooms, loaded: _vm.loadedPhoneMasthead },
          }),
          _vm.loaded
            ? _c("b-container", [
                _vm.arePhonesActive
                  ? _c(
                      "div",
                      [
                        _c("chat-window", {
                          ref: "myChat",
                          attrs: {
                            height: "calc(100vh - 210px)",
                            "accepted-files": _vm.acceptedFiles,
                            "current-user-id": _vm.currentUserId,
                            rooms: _vm.rooms,
                            "room-id": _vm.currentRoomId,
                            messages: _vm.messages,
                            "show-audio": false,
                            "show-reaction-emojis": false,
                            "loading-rooms": _vm.loadingRooms,
                            "rooms-loaded": _vm.roomsLoaded,
                            "messages-loaded": _vm.messagesLoaded,
                            "message-actions": _vm.messageActions,
                            "menu-actions": _vm.menuActions,
                            "room-actions": _vm.roomActions,
                            styles: _vm.chatStyle,
                          },
                          on: {
                            "send-message": _vm.sendMessage,
                            "delete-message": _vm.deleteMessage,
                            "fetch-messages": _vm.fetchMessages,
                            "add-room": _vm.addRoom,
                            "open-file": _vm.downloadFile,
                            "menu-action-handler": function ($event) {
                              return _vm.menuActionHandler($event)
                            },
                            "room-action-handler": function ($event) {
                              return _vm.menuActionHandler($event)
                            },
                          },
                        }),
                        _vm.showAddRoom
                          ? _c("add-new-chat-room", {
                              ref: "add-chat-room",
                              attrs: {
                                "virtual-phone-id": _vm.selectedPhone.id,
                                "virtual-phone-number":
                                  _vm.selectedPhone.number,
                                "invited-number": _vm.invitedNumber,
                              },
                              on: {
                                resetRooms: _vm.resetRooms,
                                showAddRoom: _vm.showAddRoom,
                                hideNewRoom: _vm.hideNewRoom,
                              },
                            })
                          : _vm._e(),
                        _vm.showNameRoom
                          ? _c("name-chat-room-modal", {
                              ref: "name-chat-room",
                              attrs: {
                                "phone-id": _vm.selectedPhone.id,
                                "room-id": _vm.nameRoomId,
                              },
                              on: {
                                showNameRoom: _vm.showNameRoom,
                                hideNameRoom: _vm.hideNameRoom,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("p", [
                        _vm._v("There are currently no active phones."),
                      ]),
                    ]),
              ])
            : _c("ct-centered-spinner", { attrs: { height: "260px" } }),
        ],
        1
      ),
      _c("contact-modal", {
        ref: "contact-modal",
        attrs: {
          title: "Add a contact",
          type: "add",
          "is-phone-contact": true,
        },
        on: { "contact-modal-close": _vm.contactModalClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "add-chat-room",
      attrs: {
        id: "add-new-chat-room",
        title: "Add new number",
        size: "md",
        "hide-footer": "",
      },
      on: { hidden: _vm.resetModal },
    },
    [
      _vm._t("body", function () {
        return [
          _c(
            "b-form-group",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("vue-phone-number-input", {
                        staticClass: "form-control",
                        attrs: {
                          "only-countries": _vm.allowedCountries,
                          "default-country-code": "US",
                          required: true,
                          clearable: true,
                        },
                        on: { update: _vm.validateNumberOnEvent },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.addNumber.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.phoneNumber,
                          callback: function ($$v) {
                            _vm.phoneNumber = $$v
                          },
                          expression: "phoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "plus-col" },
                    [
                      _c("fa-icon", {
                        staticClass: "plus",
                        attrs: { size: "lg", icon: "plus-square" },
                        on: { click: _vm.addNumber },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.numberList, function (number) {
            return _c(
              "b-card",
              {
                key: number,
                staticClass: "mb-1",
                attrs: { "no-body": "", "align-v": "center" },
              },
              [
                _c(
                  "b-row",
                  { staticClass: "mb-2 mt-2 ml-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "10" } },
                      [_c("b-card-text", [_vm._v(_vm._s(number))])],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c("fa-icon", {
                          staticClass: "remove",
                          attrs: { size: "lg", icon: "ban" },
                          on: {
                            click: function ($event) {
                              return _vm.removeNumber(number)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c("hr", { staticClass: "pt-1 pb-1" }),
          _c(
            "b-button",
            {
              attrs: {
                type: "submit",
                variant: "primary",
                disabled: _vm.numberList.length <= 0,
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("\n      Start Conversation\n    ")]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }